import { ethers } from 'ethers';
const POLLING_INTERVAL = 18000;
export const getLibrary = (
    provider:
        | ethers.providers.ExternalProvider
        | ethers.providers.JsonRpcFetchFunc
): ethers.providers.Web3Provider => {
    const library = new ethers.providers.Web3Provider(provider);
    library.pollingInterval = POLLING_INTERVAL;

    return library;
};
