/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState, useCallback } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWallet } from 'use-wallet';
import Web3 from 'web3';
import axios from 'axios';

import { getBalance } from 'utils/erc20';
import { Project } from 'common/Project';
import { currentConfig } from 'common/configs';

export interface ProjectContext {
    project?: Project;
    block: number;
    stats: any;
    payBalance: string;
}

export const Context = createContext<ProjectContext>({
    project: undefined,
    block: 0,
    stats: undefined,
    payBalance: '0',
});

const ProjectProvider: React.FC = ({ children }) => {
    const {
        ethereum,
        connect,
        account,
    }: { ethereum: any; connect: any; account: any } = useWallet();
    const [project, setProject] = useState<Project>();
    const [block, setBlock] = useState(0);
    const [stats, setStats] = useState<any>();
    const [payBalance, setPayBalance] = useState('0');

    // @ts-ignore
    window.eth = ethereum;

    useEffect(() => {
        if (!ethereum) return;

        const web3 = new Web3(ethereum);
        const interval = setInterval(async () => {
            const latestBlockNumber = await web3.eth
                .getBlockNumber()
                .catch(() => 0);
            setBlock((pre) => Math.max(pre + 1, latestBlockNumber));
        }, 3000);

        return () => clearInterval(interval);
    }, [ethereum]);

    useEffect(() => {
        if (ethereum) {
            const lib = new Project(ethereum, {
                defaultAccount: ethereum.selectedAddress,
                defaultConfirmations: 1,
                autoGasMultiplier: 1.5,
                accounts: [],
                ethereumNodeTimeout: 10000,
            });

            setProject(lib);
        } else {
            if (!project) {
                const lib = new Project(null, {
                    defaultConfirmations: 1,
                    autoGasMultiplier: 1.5,
                    accounts: [],
                    ethereumNodeTimeout: 10000,
                });
                setProject(lib);
            }
            const injected = new InjectedConnector({});
            injected.isAuthorized().then((isAuthorized) => {
                if (isAuthorized) {
                    let isSignOut = localStorage.getItem('signOut');
                    if (!isSignOut) {
                        connect('injected');
                    }
                }
            });
        }
    }, [ethereum]);

    const fetchStats = useCallback(async (source) => {
        try {
            const res = await axios.get(
                'https://api.setf.lol/public/token-info?token=SETF&chain=FANTOM',
                {
                    cancelToken: source.token,
                }
            );

            if (res && res.data) {
                const stats = res.data;
                setStats(stats);
            }
        } catch (err) {
            if (axios.isCancel(err)) {
            } else {
                throw err;
            }
        }
    }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();

        fetchStats(source);

        const refreshStats = setInterval(() => {
            fetchStats(source);
        }, 5000);

        return () => {
            clearInterval(refreshStats);
            source.cancel();
        };
    }, []);

    useEffect(() => {
        if (!ethereum || !account) return;

        const fetchBalance = async () => {
            const balance = await getBalance(
                ethereum,
                currentConfig.contractAddresses.TokenPay,
                account
            );
            setPayBalance(balance);
        };

        fetchBalance();
    }, [block, ethereum, account]);

    return (
        <Context.Provider value={{ project, block, stats, payBalance }}>
            {children}
        </Context.Provider>
    );
};

export default ProjectProvider;
