import { notification } from 'antd';
import warningIcon from 'assets/images/home/success.svg';
import successIcon from 'assets/images/home/warning.svg';
import infoIcon from 'assets/images/home/info.svg';
import deleteIcon from 'assets/images/home/delete.png';
import './style.scss';

export declare type IconType = 'success' | 'info' | 'error' | 'warning';

export const openNotificationWithIcon = (
    type: IconType,
    duration: number,
    message: any,
    description: any
) => {
    const Icons = {
        success: successIcon,
        warning: warningIcon,
        info: infoIcon,
        error: deleteIcon,
    };

    const Styles = {
        success: 'noti--sucess',
        warning: 'noti--warrning',
        info: 'noti--info',
        error: 'noti--err',
    };
    return notification[type]({
        className: Styles[type],
        duration: duration,
        message: message,
        description: description,
        icon: type in Icons ? <img src={Icons[type]} alt="icon" /> : null,
    });
};
