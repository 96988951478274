import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { BigNumber as BN } from 'ethers';
export function getBalance(balance: string, decimals = 18): number {
    return parseFloat(Web3.utils.fromWei(balance));
}

export function formatNumber(n: number | string, displayDecimal = 4) {
    if (typeof n === 'string') n = parseFloat(n);

    if (!n) return n;

    const order = Math.floor(Math.log10(n) / 3);

    if (order < 0) {
        return n.toFixed(displayDecimal);
    }

    const units = ['', 'K', 'M', 'B', 'T', 'Q'];
    const num = n / 1000 ** order;
    return units[order] !== undefined
        ? num.toFixed(displayDecimal) + units[order]
        : '∞';
}
export const formatTicket = (number: number) => {
    if (number < 0) return '--';

    if (number < 10) return '0' + number.toString();
    return number;
};
export function getBalanceBN(balance: BN | string, decimals = 18): number {
    return new BigNumber(balance.toString())
        .div(new BigNumber(10).pow(decimals))
        .toNumber();
}
export function abbreviateNumber(n: number, decimals = 4) {
    const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'Q'];

    // what tier? (determines SI symbol)
    const tier = (Math.log10(Math.abs(n)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier <= 0) return n.toFixed(decimals);

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = n / scale;

    // format number and add suffix
    return scaled.toFixed(decimals) + suffix;
}
export function convertLotteryDollarNumber(num: number, minimumZero = 2) {
    var count = -Math.floor(Math.log(num) / Math.log(10) + 1);
    if (num !== 0 && count >= minimumZero) {
        let start = Math.floor(num);
        let decimal = num - Math.floor(num) + '';
        if (decimal.includes('e-')) {
            let splitAfterZero = decimal.split('e-')[0];
            let end = 0;
            if (splitAfterZero.includes('.')) {
                let after = parseFloat(splitAfterZero.replace('.', ''));
                var length = after.toString().length;
                if (length > 2) {
                    after = parseInt(
                        (after / Math.pow(10, length - 2)).toFixed()
                    );
                }
                end = after;
            } else {
                // var length = end.toString().length
                end = parseInt(
                    (parseInt(splitAfterZero) / Math.pow(10, 0)).toFixed()
                );
            }
            return { origin: num, start, end, count };
        } else {
            let splitAfterZero = decimal.split('.')[1];
            var endString = splitAfterZero?.slice(count, splitAfterZero.length);
            let end = 0;
            if (endString?.length <= 2) {
                end = parseInt(endString);
            } else {
                let endStringCut = parseInt(
                    (
                        parseInt(endString) / Math.pow(10, endString.length - 2)
                    ).toFixed()
                );
                end = endStringCut;
            }
            return { origin: num, start, end, count };
        }
    }
    return { origin: num, count };
}
export function convertCountZeroNumber(
    num: number,
    minimumZero = 4,
    endStringLength = 4
) {
    var count = -Math.floor(Math.log(num) / Math.log(10) + 1);
    if (num !== 0 && count >= minimumZero) {
        let start = Math.floor(num);
        let decimal = num - Math.floor(num) + '';
        if (decimal.includes('e-')) {
            let splitAfterZero = decimal.split('e-')[0];
            let end = 0;
            if (splitAfterZero.includes('.')) {
                let after = parseFloat(splitAfterZero.replace('.', ''));
                var length = after.toString().length;
                if (length > endStringLength) {
                    after = parseInt(
                        (
                            after / Math.pow(10, length - endStringLength)
                        ).toFixed()
                    );
                }
                end = after;
            } else {
                // var length = end.toString().length
                end = parseInt(
                    (parseInt(splitAfterZero) / Math.pow(10, 0)).toFixed()
                );
            }
            return { origin: num, start, end, count };
        } else {
            let splitAfterZero = decimal.split('.')[1];
            var endString = splitAfterZero?.slice(count, splitAfterZero.length);
            let end = 0;
            if (endString?.length <= endStringLength) {
                end = parseInt(endString);
            } else {
                let endStringCut = parseInt(
                    (
                        parseInt(endString) /
                        Math.pow(10, endString.length - endStringLength)
                    ).toFixed()
                );
                end = endStringCut;
            }
            return { origin: num, start, end, count };
        }
    }
    return { origin: num, count };
}
