import { commify } from 'ethers/lib/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { convertCountZeroNumber } from 'utils/formatBalance';

interface SmallTextNumberProps {
    val: string;
    decimal?: number;
}

const SmallTextNumber: React.FC<SmallTextNumberProps> = ({
    val = '--',
    decimal,
}) => {
    const convertNumber =
        val !== '--' ? convertCountZeroNumber(parseFloat(val)) : null;
    return (
        <StyleWapper className="small-text-number">
            {convertNumber && convertNumber.count < 4 && (
                <span>
                    {'$' + commify(parseFloat(val).toFixed(decimal || 6))}
                </span>
            )}
            {convertNumber && convertNumber.count >= 4 && (
                <span>
                    <span className="start">{'$' + convertNumber.start}</span>.0
                    <span className="zero-count">{convertNumber.count}</span>
                    <span className="end">{convertNumber.end}</span>
                </span>
            )}
            {!convertNumber && <span>{val}</span>}
        </StyleWapper>
    );
};
export default SmallTextNumber;
const StyleWapper = styled.span`
    .zero-count {
        position: relative;
        top: 5px;
        font-size: 14px;
        margin: 0 1.5px;
    }
    .d-none {
        display: none;
    }
`;
