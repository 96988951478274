import { Config } from './type';

export const configs: Record<string, Config> = {
    development: {
        chainId: 137,
        contractAddresses: {
            TokenLottery: '0x0000000000000000000000000000000000000000',
            TokenPay: '0x3c1e83CA37b428e78B0adaA1EDFF5d7Fa92123a0',
            Lottery: '0x71faa7439c03ee56f3265afc3689b9f69c23be82',
        },

        MATIC: '0x0000000000000000000000000000000000001010',
        PAY: '',
        explorer: 'https://ftmscan.com',
        explorerName: 'PolygonScan',
        defaultProvider: 'https://polygon-rpc.com/',
        apiUrl: '',
        fusionFee: 1,
        defusionFee: 5,
        subgraphBattle: '',
    },
    production: {
        chainId: 137,
        contractAddresses: {
            TokenPay: '0x3c1e83CA37b428e78B0adaA1EDFF5d7Fa92123a0',
            Lottery: '0x71faa7439c03ee56f3265afc3689b9f69c23be82',
            TokenLottery: '0x0000000000000000000000000000000000000000',
        },

        MATIC: '0x0000000000000000000000000000000000001010',
        PAY: '',
        explorer: 'https://ftmscan.com',
        explorerName: 'PolygonScan',
        defaultProvider: 'https://polygon-rpc.com/',
        apiUrl: '',
        fusionFee: 5000,
        defusionFee: 10000,
        subgraphBattle: '',
    },
};

export const currentConfig =
    configs[process.env.REACT_APP_ENV || 'development'] || {};
