import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UseWalletProvider } from 'use-wallet';
import { Web3ReactProvider } from '@web3-react/core';
import 'antd/dist/antd.css';
import './assets/fonts/fonts.scss';
import './assets/scss/index.scss';

import Menu from 'views/Common/Menu/Menu';
import FooterPage from './views/Common/FooterPage/FooterPage';
import ProjectProvider from './contexts/ProjectProvider';
import { ModalContextProvider } from 'contexts/ModalContext';
import { MarketContextProvider } from 'contexts/MarketContext';
import { LayoutProject } from 'views/Common/LayoutProject/LayoutProject';
import Loading from 'views/Common/Loading';
import ScrollToTop from 'views/Common/ScrollToTop';
import { YourProjectContextProvider } from 'contexts/ProjectContext';
import LayoutBackHeader from 'views/Common/HeaderBack/LayoutBackHeader';
import { FANTOM_ID } from 'utils/common.js';
import { getLibrary } from 'utils/provider';
import { LotteryContextProvider } from 'contexts/LotteryContext';
// import V3SFinanceProvider from 'contexts/V3SFinanceProvider';

const Home = lazy(() => import('./views/Home/Home'));
const Lottery = lazy(() => import('./views/Lottery'));
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));

function App() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Providers>
                <Suspense fallback={<Loading />}>
                    <ModalContextProvider>
                        <LotteryContextProvider>
                            <MarketContextProvider>
                                <YourProjectContextProvider>
                                    {/* <V3SFinanceProvider> */}
                                    <Router>
                                        <ScrollToTop />
                                        <Switch>
                                            <Route
                                                path="/dashboard"
                                                component={Dashboard}
                                            />
                                            <Route
                                                path="/lottery"
                                                component={Lottery}
                                            />
                                            <Layout
                                                path="/"
                                                exact
                                                component={Home}
                                            ></Layout>
                                        </Switch>
                                    </Router>
                                    {/* </V3SFinanceProvider> */}
                                </YourProjectContextProvider>
                            </MarketContextProvider>
                        </LotteryContextProvider>
                    </ModalContextProvider>
                </Suspense>
            </Providers>
        </Web3ReactProvider>
    );
}

const Providers: React.FC = ({ children }) => {
    return (
        <UseWalletProvider chainId={FANTOM_ID}>
            <ProjectProvider>{children}</ProjectProvider>
        </UseWalletProvider>
    );
};

export const Layout = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <>
                    <Menu />
                    <Component {...props} />
                    <FooterPage />
                </>
            )}
        />
    );
};
export const LayoutRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <LayoutProject>
                    <Component {...props} />
                </LayoutProject>
            )}
        />
    );
};

export const BackHeaderRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <LayoutBackHeader>
                    <Component {...props} />
                </LayoutBackHeader>
            )}
        />
    );
};

export default App;
