import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Config } from './type';
import { currentConfig } from './configs';
import { abis } from './abis';

export class Contracts {
    private config: Config;
    public tokens: any = {};

    constructor(provider: any, public web3: Web3) {
        this.config = currentConfig;
        // this.PAY = new this.web3.eth.Contract(payAbi)

        this.setProvider(provider);
    }

    setProvider(provider: any) {
        const setProvider = (contract: any, address: string) => {
            contract.setProvider(provider);
            if (address) {
                contract.options.address = address;
            } else console.error('Contract address not found in network');
        };
    }

    setDefaultAccount(account: string) {}
}
