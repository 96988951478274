import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';

type Props = {
  className?: string;
  href: string;
  title?: string;
  target?: string
};

const ExternalLink: FC<Props> = ({ className, href, title, children, target }) => {
  if (!isMobile) {
    return (
      <a className={className} href={href} title={title} target={target || "_blank"} rel="noreferrer">
        {children}
      </a>
    );
  } else {
    return (
      <a className={className} href={href} title={title}>
        {children}
      </a>
    );
  }
};

export { ExternalLink };
