import icondiscord from '../../../assets/images/discord.svg';
import iconMedium from '../../../assets/images/ic_medium.svg';
import logo from '../../../assets/images/logo.svg';
import icontelegram from '../../../assets/images/telegram.svg';
import icontwitter from '../../../assets/images/home/icon2.svg';
import './footerPage.scss';
import { socials } from 'views/Home/components/BannerHome';

export default function FooterPage() {
    return (
        <div className="footer-page">
            <div className="container-footer">
                {' '}
                <div className="top-footer">
                    <div className="logo-footer">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="list-row-item">
                        {socials.map((item, id) => (
                            <a key={id} href={item.link}>
                                <img
                                    src={item.icon}
                                    className="style-icon-link"
                                    alt="social"
                                />
                            </a>
                        ))}
                    </div>
                </div>
                {/* <div className="email-contact">
                    Contact:{' '}
                    <a href="mailto:contact@mememonster.io">
                        contact@mememonster.io
                    </a>
                </div> */}
                <div className="description-contact">
                    The Content shared on this website is for information
                    purpose only and, thus, should not be considered as
                    financial advice. Trading/Investing is risky and you should
                    never invest more than you can afford to lose. You alone
                    assume the sole responsibility of evaluating the merits and
                    risks associated with the use of any information or other
                    Content on the Site before making any decisions based on
                    such information or other Content.
                </div>
            </div>
        </div>
    );
}
