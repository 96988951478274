import Web3 from 'web3';
import BigNumber from 'bignumber.js';

import { Contracts } from './contracts';
import { currentConfig } from './configs';

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
});

export class Project {
    public web3: Web3;
    public contracts: Contracts;

    constructor(provider: any, options: any) {
        var realProvider = provider || currentConfig.defaultProvider;

        this.web3 = new Web3(realProvider);
        if (options.defaultAccount) {
            this.web3.eth.defaultAccount = options.defaultAccount;
        }

        this.contracts = new Contracts(realProvider, this.web3);
    }

    setProvider(provider: any) {
        this.web3.setProvider(provider);
        this.contracts.setProvider(provider);
    }

    setDefaultAccount(account: string) {
        this.web3.eth.defaultAccount = account;
        this.contracts.setDefaultAccount(account);
    }

    getDefaultAccount() {
        return this.web3.eth.defaultAccount;
    }

    toBigN(a: any) {
        return new BigNumber(a);
    }
}
