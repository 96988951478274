import { createContext, useState } from 'react';

interface ContextProps {
    visible: boolean;
    handleVisible: any;
}

const defaultValue = {
    visible: false,
    handleVisible: () => {},
};
export const ModalConext = createContext<ContextProps>(defaultValue);

export const ModalContextProvider: React.FC = ({ children }) => {
    const [visible, setVisible] = useState(defaultValue.visible);
    const handleVisible = () => {
        setVisible(!visible);
    };

    const value = {
        visible,
        handleVisible,
    };

    return (
        <ModalConext.Provider value={value}>{children}</ModalConext.Provider>
    );
};
