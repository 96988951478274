import _ from 'lodash';
import { createContext, useState } from 'react';

interface filterType {
    type: string;
    typeTab: string;
    Rarity: string;
}

type ContextProps = {
    filters: filterType;
    handleChangleFilter: (filterKey: string, filterValue: any) => void;
};

const defaultValue = {
    filters: {
        type: '',
        typeTab: 'superhero',
        Rarity: '',
    },
    handleChangleFilter: (filterKey: string, filterValue: any) => {},
};

export const YourProjectContext = createContext<ContextProps>(defaultValue);

export const YourProjectContextProvider: React.FC = ({ children }) => {
    const [filters, setFilter] = useState(defaultValue.filters);

    const handleChangleFilter = (key: string, value: any) => {
        setFilter({
            ...filters,
            [key]: _.isArray(value) ? value.join(',') : value,
        });
    };

    const value = {
        filters,
        handleChangleFilter,
    };

    return (
        <YourProjectContext.Provider value={value}>
            {children}
        </YourProjectContext.Provider>
    );
};
