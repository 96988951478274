import Web3 from 'web3';
import { provider } from 'web3-core';
import { Contract } from 'web3-eth-contract';
import { getAddress } from 'ethers/lib/utils';

import { abis } from '../common/abis';

export const getContract = (provider: provider, address: string) => {
    const web3 = new Web3(provider);
    let contract = new web3.eth.Contract(abis.ERC20Abi, address);
    contract.options.address = address;

    return contract;
};

export const getAllowance = async (
    contract: Contract,
    spender: string,
    account: string
): Promise<string> => {
    try {
        const allowance: string = await contract.methods
            .allowance(account, spender)
            .call();
        return allowance;
    } catch (e) {
        return '0';
    }
};

export const getBalance = async (
    provider: provider,
    tokenAddress: string,
    userAddress: string
): Promise<string> => {
    const contract = getContract(provider, tokenAddress);
    try {
        const balance: string = await contract.methods
            .balanceOf(userAddress)
            .call();
        return balance;
    } catch (e) {
        return '0';
    }
};

export function toChecksum(address: string) {
    try {
        return getAddress(address?.toLowerCase());
    } catch (e) {
        return '';
    }
}
