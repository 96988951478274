import { useContext } from 'react';

import { Context } from '../contexts/ProjectProvider';

const useStats = () => {
    const { stats } = useContext(Context);

    return stats;
};

export default useStats;
