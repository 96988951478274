import { commify } from 'ethers/lib/utils';
import { useHistory } from 'react-router-dom';

import icon1 from 'assets/images/home/icon1.svg';
import icon2 from 'assets/images/home/icon2.svg';
import icon3 from 'assets/images/home/icon3.svg';
import icon4 from 'assets/images/home/icon4.svg';
import { ExternalLink } from 'components/Common/ExternalLink';
import useStats from 'hooks/useStats';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import SmallTextNumber from 'views/Lottery/components/SmallTextNumber';
import animalImage from '../../../assets/images/home/animals.png';
import './bannerHome.scss';

export const socials = [
    {
        link: 'https://t.me/SonicETF',
        icon: icon1,
    },
    {
        link: 'https://x.com/sonicetf',
        icon: icon2,
    },
    {
        link: 'https://linktr.ee/sonicetf',
        icon: icon3,
    },
    {
        link: 'https://dexscreener.com/fantom/0x417eb283446473eba0e222a507f03fd136f95393',
        icon: icon4,
    },
];
export default function BannerHome() {
    const history = useHistory();
    const stats = useStats();
    const data = stats?.SETF;
    // console.log('stats', stats);
    return (
        <div className="banner-home">
            <div className="container-banner">
                <div className="box-content-banner">
                    {' '}
                    <div className="left-banner ">
                        <Fade left>
                            {/* <div className="title-1">
                                Play game, earn{' '}
                                <span className="name-token">$MEME.</span>{' '}
                                <br className="break-line" />
                            </div> */}
                            <div className="title-1">
                                Every <span className="name-token">$S</span>{' '}
                                Holder
                                <br className="break-line" />
                                Need <span className="name-token">$SETF</span>
                            </div>

                            <div className="description-1">
                                $BTC ETF approved $ETH ETF approved ... $S ETF
                                next?
                            </div>
                        </Fade>
                        <Fade left>
                            <div className="row-list-btn">
                                <button
                                    className="btn-buy-swap btn-primary"
                                    onClick={() =>
                                        window.open(
                                            `https://spooky.fi/#/best-swap?inputCurrency=ETH&outputCurrency=0x3c1e83CA37b428e78B0adaA1EDFF5d7Fa92123a0`
                                        )
                                    }
                                >
                                    Buy on SpookySwap
                                </button>
                                <button
                                    className="btn-buy-swap btn-primary"
                                    onClick={() =>
                                        window.open(
                                            `https://app.microswap.org/swap/FTM/0x3c1e83CA37b428e78B0adaA1EDFF5d7Fa92123a0`
                                        )
                                    }
                                >
                                    Buy on MicroSwap
                                </button>
                                {/* <button
                                    className="btn-launch btn-secondary"
                                    onClick={() => history.push('/dashboard')}
                                >
                                    Launch DashBoard
                                </button> */}
                                <ListSocial>
                                    {socials.map((item) => {
                                        return (
                                            <ExternalLink
                                                href={item.link}
                                                target="_blank"
                                            >
                                                <img
                                                    src={item.icon}
                                                    className="style-icon-link"
                                                    alt="tele"
                                                />
                                            </ExternalLink>
                                        );
                                    })}
                                </ListSocial>
                            </div>
                        </Fade>
                    </div>{' '}
                    <div className="right-banner ">
                        <Fade right>
                            <img
                                src={animalImage}
                                className="style-banner-right"
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>
                <Fade bottom>
                    <div className="stats-info ">
                        <div className="item-info">
                            <div className="title-i">$SETF price</div>
                            <div className="price-i">
                                {/* {data?.price
                                    ? `${formatNumber(
                                          stats?.price?.toFixed(8),
                                          6
                                      )}`
                                    : '--'} */}
                                {data?.price ? (
                                    <SmallTextNumber
                                        val={data?.price?.toString()}
                                        decimal={5}
                                    />
                                ) : (
                                    '$--'
                                )}
                            </div>
                        </div>
                        <div className="item-info">
                            <div className="title-i">Marketcap</div>
                            <div className="price-i">
                                $
                                {data?.circulatingSupply
                                    ? commify(
                                          (
                                              data?.circulatingSupply *
                                              data?.price
                                          ).toFixed(0)
                                      )
                                    : '--'}
                            </div>
                        </div>
                        <div className="item-info">
                            <div className="title-i">Total holders</div>
                            <div className="price-i">
                                {data?.holder
                                    ? commify(parseInt(data?.holder))
                                    : '--'}
                            </div>
                        </div>
                        <div hidden className="item-info">
                            <div className="title-i">Total volume</div>
                            <div className="price-i">
                                $--
                                {/*   {stats?.burnToken
                                    ? commify(stats?.burnToken.toFixed(0))
                                    : '--'}{' '}
                               */}
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

const ListSocial = styled.div`
    display: flex;
    gap: 24px;
    margin-left: 12px;
    align-items: center;
    @media screen and (max-width: 700px) {
        margin-left: 0;
        margin: 12px 0;
        justify-content: center;
    }
`;
