import { createContext, useState } from 'react';

interface ContextProps {
    listChosenNumbers: any;
    updateList: any;
}

const defaultValue = {
    listChosenNumbers: [],
    updateList: (data: any) => {},
};
export const LotteryConext = createContext<ContextProps>(defaultValue);

export const LotteryContextProvider: React.FC = ({ children }) => {
    const [listChosenNumbers, setListChosenNumbers] = useState(
        defaultValue.listChosenNumbers
    );
    const updateList = (data: any) => {
        setListChosenNumbers(data);
    };

    const value = {
        listChosenNumbers,
        updateList,
    };

    return (
        <LotteryConext.Provider value={value}>
            {children}
        </LotteryConext.Provider>
    );
};
